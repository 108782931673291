import type { ComponentType } from "react"
import { createStore } from "https://framer.com/m/framer/store.js@^1.0.0"

const useStore = createStore({
    hovered: false,
})

export function withConditionalHover(Component): ComponentType {
    return (props) => {
        const [store, setStore] = useStore()

        return (
            <Component
                {...props}
                initial={{ scale: 1 }}
                whileHover={props.link ? { scale: 1.05 } : {}}
                onHoverStart={() => {
                    if (props.link) setStore({ hovered: true })
                }}
                onHoverEnd={() => {
                    setStore({ hovered: false })
                }}
            />
        )
    }
}
